/* tinymce infos card */
.tiny-info-card {
  color: #151B26;
  width: 100%;
  height: 100%;
  background: #F5F6FA;
  overflow: hidden;
  border-radius: 4px;
  outline: 1px black solid;
  outline-offset: -0.50px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;

  .tiny-info-card-icon-block {
    width: 85px;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    gap: 4px;
    display: flex;

    i {
      color: #151B26 !important;
      font-size: 24px;
    }
  }

  .tiny-info-card-body {
    flex: 1 1 0;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    display: inline-flex;

    .tiny-info-card-body-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      word-wrap: break-word;
    }

    .tiny-info-card-body-content {
      align-self: stretch;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      word-wrap: break-word
    }
  }
}

.tiny-info-card-good-to-know {
    .tiny-info-card-icon-block {
        background-color: #1FD286;
    }
}

.tiny-info-card-recommend {
    .tiny-info-card-icon-block {
      background-color: #333752;
      i {
        color: white !important;
      }
    }
}

.tiny-info-card-warning {
    .tiny-info-card-icon-block {
        background-color: #F99600;
    }
}

.tiny-info-card-to-remind {
    .tiny-info-card-icon-block {
      background-color: #1E5EFF;
      i {
        color: white !important;
      }
    }
}