
.dropdown-item:active, .dropdown-item.active {
  background-color: $electric-blue;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border: 0;
  border-top: 2px solid;
  border-right: 2px solid;
  width: 10px;
  height: 10px;
  transform: rotate(135deg);
  position: absolute;
  right: 10%;
  top: 25%;
}


.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:not(.disabled):hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: 0;
  content: "";
  border: 0;
  border-top: 1px solid;
  border-right: 1px solid;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

.vue-dropdown {

  .dropdown-autocomplete-form {
    box-shadow: $default-shadow;
    .form-group {
      margin-bottom: 1rem;
      position: relative;
    }

    .dropdown-autocomplete-loading {
      position: absolute;
      top: calc(50% - 0.5rem);
      right: 1rem;
      font-size: 1rem !important;
    }
  }

  .dropdown-result-body {
    margin-top: 1rem;
    max-height: 290px;
    overflow: auto;
    @extend .custom_scroll_bar;

    li {
      min-height: 30px;
    }
  }
}