.about-us {
  overflow-x: hidden;
  .promember-breadcrumb {
    background-color: #DCEFF1;
    #breadcrumb {
      padding: 0;
      max-width: 1140px;
      margin-top: 0!important;
      ol.breadcrumb {
        margin-bottom: 0;
        background-color: unset;
        li {
          font-size: .875rem;
        }
      }
    }
  }
  h1 {
    text-transform: uppercase;
    color: $testimony-blue;
    font-size: 3rem;
    font-weight: 900;
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
    }
  }

  h2, .h2 {
    font-size: 2.5rem;
    font-weight: 900;
    margin-bottom: 0;
    line-height: 2.2rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.9rem;
      line-height: 1;
    }
  }
  .website {
    color: black;
    font-size: 2rem;
    font-weight: 800;
    padding-bottom: .5rem;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  h3, .h3 {
    font-weight: 400;
    font-size: 1.7rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    @include media-breakpoint-down(sm) {
      line-height: 1.7rem;
    }
  }

  .btn.btn-outline-white, .btn.btn-outline-testimony {
    padding: .6rem 2rem;
  }

  img.aladom-actions {
    width: 100%;
    max-width: 180px;
    @include media-breakpoint-down(md) {
      max-width: 150px;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  #team {
    padding-bottom: 10rem;

    h2 {
      color: $black;
      font-weight: 900;
      padding-top: 4rem;
      @include media-breakpoint-down(md) {
        padding: 5rem .5rem 0;
      }
    }
    h3 {
      padding-bottom: 1rem;
      color: $black;
      font-size: 2rem;
      padding: .5rem 0 2rem;
    }

    h2, h3 {
      @include media-breakpoint-down(md) {
        text-align: center;
        font-size: 1.8rem;
      }
    }

    .team-people {
      max-width: 250px;
      height: 375px;
      position: relative;
      @include media-breakpoint-up(md) {
        margin: .5rem;
      }
      max-height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .team-text {
      position: absolute;
      background-color: rgba($testimony-blue, .69);
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding: 1rem;
      transition: opacity .8s ease-in-out;

      &:hover:not(.no-picture) {
        opacity: 0;
      }

      h4 {
        font-weight: 900;
        font-size: 1.1rem;
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
      p {
        font-size: .85rem;
        line-height: 1.1;
        @include media-breakpoint-down(md) {
          font-size: .8rem;
        }
      }
      h4, p {
        color: white;
        text-transform: uppercase;
      }
    }
  }

  .breadcrumb {
    background-color: transparent;
  }

  #services {
    @include media-breakpoint-up(md) {
      height: 800px;
    }
    @include media-breakpoint-up(lg) {
      height: 775px;
    }

    h3 {
      color: $black;
      font-weight: 800;
      font-size: 2rem;
      @include media-breakpoint-down(md) {
        font-size: 1.7rem;
      }
      @include media-breakpoint-down(sm) {
        text-align: center;
        margin-bottom: 1rem;
      }
    }
    .services-list-count {
      display: block;
      text-align: center;
      @include media-breakpoint-up(md) {
        position: absolute;
        left: -10rem;
      }

      img {
        max-width: 100%;
        width: 140px;
        @include media-breakpoint-down(sm) {
          padding-bottom: 2rem;
        }
      }
     }

    .ribbon-services {
      position: absolute;
      height: 100%;
      left: calc(50% - 145px);
      bottom: 0;
    }

    .col {
      padding: 3rem 0;
    }

    .row {
      max-width: 900px;
    }
  }

  .banner {
    min-height: 80vh;
    @include media-breakpoint-up(xl) {
      min-height: 90vh;
    }
    @include media-breakpoint-down(md) {
      min-height: unset;
    }
    background-color: transparent;
    color: $black;
    text-align: left;
    padding-bottom: 1rem;
    position: relative;
    flex-direction: column;

    .row {
      width: 100%;
      max-width: 1140px;
      @include media-breakpoint-up(md) {
        padding: 5rem 0;
      }
    }

    .logo-aladom {
      max-width: 370px;
      margin: auto;
      width: 100%;
      display: block;
    }

    .ribbon-331 {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 900px;
      max-width: 70%;
    }

    .ribbon-white {
      position: absolute;
      max-height: 80%;
      left: 0;
      bottom: 0;
    }

    &.blue-banner {
      background-color: $testimony-blue;
      color: white;

      a:not(.btn) {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
        &, &:hover {
          color: white;
        }
      }

      h2, h3, .h2, .h3 {
        color: white;
      }
      label,p {
        color: white;
      }
    }

    #about_us {
      padding-bottom: 6rem;
    }

    #made_in_bretagne {
      max-width: 800px;
      @include media-breakpoint-down(md) {
        padding-bottom: 4rem;
        padding-top: 4rem;
      }

      @include media-breakpoint-up(md) {
        p {
          line-height: 1.5rem;
        }
      }
      @include media-breakpoint-down(md) {
        h2, h3 {
          text-align: center;
        }

        h3 {
          padding-top: 1rem;
          font-size: 1.5rem;
        }

        .h2 {
          font-size: 1.8rem;
        }
        .h3 {
          font-size: 1.4rem;
        }

        p {
          font-weight: 300;
          line-height: 1.7rem;
          margin-bottom: 2rem;
        }
      }
      .btn-outline-testimony {
        &:hover {
          background-color: black !important;
        }
      }
    }

    #role {
      padding-bottom: 6rem;
      @include media-breakpoint-down(md) {
        padding-bottom: 5rem;
        padding-top: 5rem;
      }

      @include media-breakpoint-down(md) {
        .h2, .h3 {
          text-align: center;
        }
        .h2 {
          font-size: 1.8rem;
        }
        .h3 {
          font-size: 1.4rem;
        }
      }
    }

    #mission {
      @include media-breakpoint-down(md) {
        padding-bottom: 5rem;
        padding-top: 5rem;
        h2 {
          font-size: 2.1rem;
          text-align: center;
        }

        .h2, .h3 {
          text-align: center;
        }
        .h2 {
          font-size: 1.8rem;
          line-height: 1.7rem;
        }
        .h3 {
          font-size: 1.4rem;
        }
      }
    }

    #valeurs {
      max-width: 900px;
      @include media-breakpoint-down(md) {
        padding-bottom: 4rem;
        padding-top: 4rem;
      }

      li {
        list-style-type: none;
        font-size: 1.6rem;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 1.5;
        @include media-breakpoint-down(md) {
          font-size: 1.3rem;
          line-height: 1.7rem;
          padding: 2rem 0;
        }

        &::first-letter {
          font-size: 4rem;
          padding-right: .3rem;
          @include media-breakpoint-down(md) {
            font-size: 3.2rem;
            line-height: 2.2rem;
          }
        }
      }
    }

    #histoire {
      max-width: 1000px;
      @include media-breakpoint-down(md) {
        padding-bottom: 4rem;
        padding-top: 4rem;
      }
      @include media-breakpoint-down(sm) {
        .frise {
          overflow-x: scroll;
          overflow-y: hidden;
          -ms-overflow-style: none;
          scrollbar-width: none;
          scroll-snap-type: x proximity;
          scroll-behavior: smooth;
          height: 90vh;
          width: 100vw;
          margin-left: -30px;
        }
      }
      .histoire-frise {
        width: 100%;
        margin-top: 3rem;
        @include media-breakpoint-up(md) {
          border-bottom: 4px solid $testimony-blue;
        }
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
          padding-left: 30px;
          position: relative;
          margin-top: 5rem;
          &:before {
            content: " ";
            width: 880vw;
            height: 10px;
            position: absolute;
            bottom: -10px;
            background-color: $black;
            left: calc(50% - 30px);
            @include media-breakpoint-down(xs) {
              left: calc(50% - 15px);
            }
          }
        }

        button {
          background-color: transparent;
          border: none;
          font-size: 1.3rem;
          font-weight: 900;
          color: $black;

          @include media-breakpoint-down(sm) {
            min-width: 80vw;
            max-width: 80vw;
            color: $testimony-blue;
            font-size: 2rem;
            padding-bottom: 1.7rem;
            position: relative;
            scroll-snap-align: center;
            cursor: default;

            &:before {
              content: " ";
              width: 40px;
              height: 40px;
              position: absolute;
              bottom: -25px;
              background-color: $testimony-blue;
              right: calc(50% - 20px);
              border-radius: 50%;
            }
            &:after {
              content: " ";
              width: 26px;
              height: 26px;
              position: absolute;
              bottom: -18px;
              background-color: $black;
              right: calc(50% - 13px);
              border-radius: 50%;
            }
          }

          &.active {
            color: $testimony-blue;
          }
        }
      }
      .frise-data {
        height: 150px;
        @include media-breakpoint-up(md) {
          & > div {
            display: none;
          }
          button.frise-nav {
            display: none;
          }
        }

        .frise-card-wrap {
          display: none;
          &.show {
            display: block;
          }
          @include media-breakpoint-down(sm) {
            display: flex;
          }
        }

        .frise-card {
          text-align: center;
        }
        @include media-breakpoint-down(sm) {
          overflow: visible;
          width: 100%;
          display: flex;
          padding-left: 30px;
          & > div {
            min-width: 80vw;
            max-width: 80vw;
            display: flex;
            justify-content: center;
          }

          .frise-card {
            box-shadow: $default-shadow;
            width: 280px;
            height: 280px;
            padding: 20px;
            @include media-breakpoint-down(xs) {
              width: 250px;
              height: 250px;
              img {
                max-height: 90px;
              }

              p {
                font-size: .95rem;
                line-height: 1.1rem;
              }
            }
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
          }
          button.frise-nav {
            position: absolute;
            background-color: $testimony-blue;
            border: 2px solid black;
            border-radius: 50%;
            color: white;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: start;
            align-items: center;
            right: -40px;
            padding: 6px;
            top: 50%;

            &.start {
              transform: scaleX(-1);
              right: unset;
              left: -40px;
            }

            i {
              font-size: 1.9rem;
            }
          }
        }
      }
      .show-2008:hover + #data-2008 {
        display: block;
      }
    }

    #actions {
      @include media-breakpoint-up(xl) {
        padding-top: 0;
        margin-top: -4rem;
      }
      @include media-breakpoint-down(md) {
        .btn.btn-outline-testimony {
          background-color: $testimony-blue;
          color: white;
        }
      }
    }

    #contact {
      padding: 4rem 1rem;
      h2 {
        text-transform: lowercase;
        &:first-letter {
          text-transform: uppercase;
        }
        @include media-breakpoint-down(md) {
          font-size: 2rem;
        }
      }
    }

    #unbounce-why {
      background-color: white;
      margin-top: 4rem;
      padding: 4rem 5rem;
      @include media-breakpoint-down(md) {
        padding: 3rem 1.5rem;
      }
      color: $black;
      p {
        font-weight: 400;
        font-size: .9rem;
      }

      i.ala-star-full {
        color: #f5cb44;
        font-size: 3rem;
      }

      h2 {
        font-weight: 300;
        color: $black;
        font-size: 1.8rem;
        text-transform: none;
      }
      h3 {
        font-weight: 800;
        color: $black;
        font-size: 1.7rem;
      }
    }

    &#ruban {
      min-height: 40vh;
      overflow: visible;

      img#ruban_actions {
        @include media-breakpoint-up(md) {
          position: absolute;
        }
        top: -2rem;
      }
    }

    &#certifications {
      padding-bottom: 10rem;

      .row {
        padding-bottom: 5rem;
      }

      h2 {
        color: $black;
      }
    }

    &#rse {
      padding-bottom: 10rem;

      #rse-title {
        padding-bottom: 6rem;
      }

      h2 {
        font-size: 2.8rem;
        line-height: 2.9rem;
        @include media-breakpoint-down(md) {
          font-size: 2.2rem;
          line-height: 1;
        }
      }

      h3 {
        color: #00F953;
        font-weight: 900;
        font-size: 2.2rem;
        padding-bottom: 1rem;
        line-height: 1;
      }

      li {
        @include media-breakpoint-down(md) {
          padding-top: 2rem;
        }
      }
    }

    #team_group {
      @include media-breakpoint-up(xl) {
        padding-top: 0;
      }

      h1 {
        font-size: 2.5rem;
        @include media-breakpoint-down(md) {
          font-size: 2rem;
        }
      }

      div.h2 {
        font-weight: 800;
      }

      div.h2, div.h3 {
        color: $black;
        font-size: 2rem;
        @include media-breakpoint-down(md) {
          font-size: 1.5rem;
        }
      }

      img.bzh-flag {
        width: 100%;
        max-width: 200px;
        @include media-breakpoint-down(md) {
          max-width: 130px;
          padding-bottom: 1rem;
        }
      }
    }
  }
}
