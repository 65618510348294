// Text Colors
//////////////
.text-azure {
  &, &::placeholder {
    color: $azure-blue !important;
  }
}

.text-electric-blue, .text-presta {
  &, &::placeholder {
    color: $electric-blue !important;
  }
}
.text-very-light-blue {
  &, &::placeholder {
    color: $very-light-blue !important;
  }
}

.text-black {
  &, &::placeholder {
    color: $black !important;
  }
}

.text-white {
  &, &::placeholder {
    color: $white !important;
  }
}

.text-testimony {
  &, &::placeholder {
    color: $testimony-blue !important;
  }
}

.text-presta {
  &, &::placeholder {
    color: $electric-blue !important;
  }
}

.text-part-emp, .text-cyan {
  &, &::placeholder {
    color: $cyan !important;
  }
}

.text-light-red {
  &, &::placeholder {
    color: $light-red !important;
  }
}

.text-green {
  &, &::placeholder {
    color: $green !important;
  }
}

.text-clear-blue {
  &, &::placeholder {
    color: $clear-blue;
  }
}

.text-podcast {
  &, &::placeholder {
    color: $pink-podcast !important;
  }
}

// Backgrounds colors
/////////////////////

.bg-podcast {
  background-color: $pink-podcast;
}

.bg-azure {
  background-color: $azure-blue !important;
}

.bg-electric-blue {
  background-color: $electric-blue !important;
}

.bg-light-grey {
  background-color: $light-grey;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.text-weight-medium {
  font-weight: 500;
}

.bg-light-red {
  background-color: $light-red !important;
}