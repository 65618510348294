// Cards
////////

.list-item-card {
  &.premium-list-item {
    padding-top: .7rem;
    padding-bottom: 1rem;
    border-radius: 6px;

    figure {
      width: 130px;
      height: 130px;
    }

    &:after {
      display: none;
    }
  }

  img.premium-img {
    width: 100px;
    height: auto;
    right: calc(50% - 50px);
    top: 50%;
    bottom: 50%;
  }
}

@include media-breakpoint-up(sm) {
  .premium_partners {
    height: 185px;
  }
}


// Badges
/////////

.option-card-badge {
  margin-top: -18px;
  text-align: center;
  position: relative;
  display: inline-block;
  &.option-card-badge-job-listing {
    margin-top: unset;
    z-index: 1000;
    position: absolute;
    bottom: 10px;
    left: 40%;
    @include media-breakpoint-down(md) {
      bottom: -10px;
    }
  }
  & + .option-card-badge {
    margin-left: 2rem;
    &.small-marge {
      margin-left: 1rem;
    }
  }
  
  .checked-identity-example & {
    display: block;
  }

  img {
    width: 32px;
    height: auto;
    display: block;
    margin: auto;
  }

  &:hover .option-card-box {
    display: block;
  }

  .option-card-box {
    display: none;
    text-transform: uppercase;
    font-size: .57rem;
    font-weight: 600;
    border-radius: 3px;
    color: white;
    position: absolute;
    top: 40px;
    padding: .3rem;
    left: calc(50% - 55px);
    width: 110px;
    z-index: 100;

    &.premium-box {
      @include option-box($premium-option-color);
    }

    &.visibility-box {
      @include option-box($visibility-color);
    }

    &.prime-box {
      @include option-box($prime-color);
    }

    &.job-box {
      @include option-box($job-offer-color);
    }

    &.checked-identity-box {
      @include option-box($checked-identity-color);
    }

    &.bconnect-box {
      @include option-box(#f8174d);
    }
  }
}

.option-badge {
  position: absolute;
  right: -20px;
  top: -25px;
  text-align: center;
  padding: 5px 10px;
  width: 40%;
}

.aladom-option-bubble-info {
  position: absolute;
  left: unset;
  right: -10px;
  top: 5px;
  font-style: normal;
  font-weight: 600;
  width: 20px;
  height: 20px;
  font-size: 14px;
  padding: 0 7px;

  &:hover, &.show {
    .dropdown-menu {
      display: block;
    }
  }
}

.dropdown-content {
  padding: 4rem 2rem;
  @include media-breakpoint-down(sm) {
    padding: 1rem;
  }

  img {
    max-width: 100%;
  }

  p {
    text-align: center;
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }
  strong {
    font-weight: 700;
  }
}

// All options
//////////////

table {
  &.prime-table, &.visibility-table {
    color: $black;
    font-size: .9rem;
    border: none;
    border-collapse: separate;
    border-spacing: 20px 0;
    @include media-breakpoint-down(sm) {
      border-spacing: 5px 0;
      font-size: .75rem;
    }

    th {
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      padding: 1.5rem .6rem;
      @include media-breakpoint-down(sm) {
        font-size: .9rem;
        padding: .5rem .2rem;
      }
    }

    th, td {
      border: none;
      @include media-breakpoint-down(sm) {
        padding: .5rem;
      }

      &.active {
        border-right: 3px solid;
        border-left: 3px solid;
      }

    }

    td.infos {
      text-align: center;
      vertical-align: middle;
    }

    strong {
      font-weight: 600;
    }

    th.choice-col {
      width: 150px;
      @include media-breakpoint-down(sm) {
        width: 75px;
      }
    }

    strong {
      font-weight: 600;
    }

    thead tr {
      background: none !important;
    }

    tr:nth-child(odd) {
      background: #F8F9FA
    }

    tr:nth-child(even) {
      background: #70707018
    }

    thead tr:first-of-type {
      .active {
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        border-top: 3px solid;
      }
    }


    tbody tr:last-of-type {
      .active {
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom: 3px solid;
      }
    }

    tr {
      transition: background .2s ease-in-out;
    }
  }

  &.prime-table {
    th.active {
      color: $cyan !important;
    }

    th, td {
      &.active {
        border-color: $cyan !important;
      }
    }
  }

  &.visibility-table {

    .free, .visibility {
      cursor: pointer;
    }

    th.active {
      color: $electric-blue !important;
    }

    th, td {
      &.active {
        border-color: $electric-blue !important;
      }
    }
  }

  .ala-remove-circle, .ala-check-thin {
    font-size: 1.5rem;
    font-weight: 600;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }
  }

  .ala-remove-circle {
    color: #E31818;
  }
  .ala-check-thin {
    color: #28C973;
  }
}

// Prime
////////

#prime-offer, #myaladom-prime-options {
  .panel {
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }

  .text-secondary {
    color: #aaa !important;
  }

  .title-prime {
    color: $white;
    background-color: $prime-blue;
  }

  .text-prime-blue {
    color: $prime-blue;
  }

  .title-peace {
    color: $white;
    background-color: $peace-blue;
  }

  .text-peace-blue {
    color: $peace-blue;
  }

  .title-peace-plus {
    color: $white;
    background-color: $peace-plus-blue;
  }

  .text-peace-plus-blue {
    color: $peace-plus-blue;
  }

  .options-table {
    display: flex;
    align-items: start;
    justify-content: space-between;

    .plan {
      width: 24%;
      position: relative;
      overflow: hidden;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      background-color: $white;
      font-size: .8rem;
      border-radius: 15px;

      .plan-title {
        height: 105px;
        text-align: center;
        display: flex;

        h4 {
          margin: auto;
          color: white;
        }
      }

      .title-free {
        background-color: #e4f8ff;
        color: #7691a6;
        h4 {
          color: #7691a6;
        }
      }

      .form-check-label {
        text-align: left;
      }

      .plan-select, .plan-cost {
        text-align: center;
        padding: 1rem .5rem;
        border-top: 1px solid $pale;
      }

      .plan-cost-free {
        display: flex;
      }

      @include media-breakpoint-only(lg) {
        .plan-select {
          height: 115px;
        }

        .plan-cost-free {
          height: 235px;
        }
      }

      .plan-description {
        border-top: 1px solid $pale;
        padding: 1rem .5rem;
        display: flex;

        em {
          margin : auto;
        }
      }

      .plan-features {
        border-top: 1px solid $pale;
        padding : 1rem;
        ul {
          padding: 0;
          margin-bottom: 0;
          margin-left: .5rem;
        }
      }

      .form-check-label:hover, .form-check-input:hover {
        cursor: pointer;
      }
    }

      @include media-breakpoint-up(xl) {
        .plan-cost {
          height: 120px;
        }

        .plan-select {
          height: 155px;
        }

        .plan-cost-free {
          height: 275px;
        }
      }

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
      align-items: normal;

      .plan {
        margin-top: 1rem;
        width: 49%;
      }
    }
    @include media-breakpoint-down(md) {
      .plan {
        width: 100%;
      }
    }
  }
}

#prime-offer {
  color: $black;
  padding-bottom: 7rem;
  font-size: .9rem;
  max-width: 800px;

  h2, .h3 {
    color: $black;
    font-weight: 700;
  }

  h2 {
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
      text-align: center;
    }
  }

  .price-option {
    color: $cyan;
    font-weight: 700;
    font-size: 1.2rem;
  }

  .custom-control-input:checked:not(:disabled) ~ .custom-control-label::before {
    background-color: white;
    border-color: white;
  }

  .custom-radio .custom-control-input:not(:disabled):checked ~ .custom-control-label::after {
    background-image : url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 8 8'%3E%3Ccircle r='4' cy='50%25' cx='50%25' fill='%2351ADB9'/%3E%3C/svg%3E") !important;
  }
}

#prime_option_box {
  background: linear-gradient(270deg, #00FFB2 21.2%, #00D9F6 99.97%);
  font-size: 1rem;
  padding: 2rem 3rem;
  line-height: 1.3;
  letter-spacing: initial;
  strong {
    font-weight: 600;
  }
  ul {
    max-width: 600px;
  }
  li {
    padding:  0 0 1rem;
    position: relative;
    @include media-breakpoint-down(sm) {
      padding-left: 1rem;
    }
  }
  .btn {
    width: 150px;
    font-size: 16px;
    font-weight: 600;
    margin: 1rem 1rem 0 0;
    &.btn-black {
      &:hover {
        background-color: transparent;
      }
    }
    &.btn-pass {
      color: $black;
      background-color: transparent;
      box-shadow: $default-shadow;
    }
    &:hover {
      border: 1px solid $black;
    }
  }

  #aladom-partners {
    position: absolute;
    left: unset;
    right: 100px;
    top: 0;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      right: -15px;
      top: 10px;
    }
  }

  .dropdown-menu.aladom-partners {
    border-radius: 20px;
    border: none;
    transform: translate(75%, -50%) !important;
    top: unset !important;
    left: -50% !important;
    width: 500px;
    @include media-breakpoint-down(sm) {
      left: -75% !important;
      width: 100%;
    }
    button.close {
      margin: 15px;
    }
  }
  .infos-box {
    background-color: white;
    border-radius: 20px;
  }
}

.text-part-emp {
  color: $cyan;
}


// Checked identity
///////////////////

.checked-identity-example {
  display: flex;
  margin: 2rem 0 3rem;
  align-items: center;
  justify-content: center;

  &-arrow {
    height: 70px;
    width: 70px;
    margin: 0 30px;
  }

  &-picture {
    width: 200px;
    border-radius: 50%;
  }
}

.checked-identity-form {
  background-color: white;
  padding: 1rem;
}

// Visibility
/////////////

.aladom-option-bubble-info {
  left: unset;
  right: -15px;

  #aladom-presta-contact-info-icon {
    @include info-logo($electric-blue);
  }

  .aladom-presta-contact-info {
    min-width: 260px;
    @include media-breakpoint-up(sm) {
      min-width: 360px;
    }
    width: 30%;

    h5 {
      font-size: 14px;
    }

    .dropdown-content {
      padding: 2rem;
      font-size: 12px;
    }
  }
}

#service-offer-create-form {
  .accept-cgv {
    @include media-breakpoint-down(xs) {
      label::before, label::after {
        top: 1rem !important;
      }
    }
  }
}

#myaladom-visibility-options {

  ul {
    list-style: none;
    padding: 0;
    li {
      margin: 1.5rem 0;
      color: $black;
      display: flex;
      align-items: center;
      i {
        margin-right: 2rem;
        font-size: 24px;
        color: $electric-blue;
      }
      span {
        font-size: 13px;
      }
    }
  }
  span {
    font-size: 1rem;
  }
}

.visibility-options-info-block {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
  height: 100%;
  color: $black;

  .visibility-parametter {
    background-color: $white;
    border-radius: 20px;
    width: 100%;
    i {
      font-size: 30px;
    }
    span, p {
      font-size:.875rem;
    }
    .visibility-city-search {
      p {
        line-height: 0;
      }
      .aladom-multichoices-api-autocomplete-list-item {
        i {
          color: white;
          right: unset;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 2rem;
  }

  i {
    font-size: 60px;
    color: $electric-blue;
    margin-bottom: 1.5rem;
  }

  .btn-push {
    margin-top: 1rem;
  }

  .dropdown {
    .dropdown-menu {
      color: $black;
      transform: translate3d(-29%, 36px, 0) !important;
      z-index: 1003;

      @include media-breakpoint-down(sm) {
        transform: translate3d(-40%, 36px, 0) !important;
      }

      h5 {
        color: $black;
      }

      p {
        text-align: justify;
      }

      .dropdown-content {
        padding: 2rem;
        min-width: 500px;
        @include media-breakpoint-down(sm) {
          min-width: 250px;

          span, .contact-access-setting-choice {
            font-size: 12px;
          }
        }

        .contact-access-setting-choice {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1rem;
          cursor: pointer;
          i {
            font-size: 30px;
            margin: 0 0 0 1rem;
          }
        }
      }
    }
  }
}