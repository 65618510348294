
// Modals
/////////

.has_modal {
  position: relative !important;
  overflow: hidden !important;

  .modal, .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
  }

  .modal.show {
    .modal-dialog {
      margin-top: 2%;
      max-height: 90%;
      overflow: auto;
      padding: 5px;
      @extend .custom_scroll_bar;
    }
  }
}

.modal-content {
  letter-spacing: -.8px;
  text-align: center;
  overflow: hidden;
  border-radius: 15px;
  border: none;
  box-shadow: $default-shadow;
  background: #F8F9FA;
  padding: 1rem;

  iframe {
    border: none;
  }

  h5 {
    color: $medium-grey;
  }

  .btn {
    color: $white;
    font-weight: 600;
    letter-spacing: 0;
  }

  #signin-modal-form button[type="submit"] {
    margin: 2rem auto 1rem;
    display: block;
  }

  hr {
    &::before, &::after {
      content: none;
    }
  }

  .form-group {
    text-align: left;
  }

  .modal-header {
    border-bottom: none;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .modal-title {
    font-size: 1.25rem;
    color: black;
    font-weight: 900;
  }

  .modal-body {
    @include media-breakpoint-up(sm) {
      padding: 1rem 3.5rem 0;
    }
    font-size: 1rem;

    input, span {
      @include modal-input();
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      @include modal-input
    }
    ::-moz-placeholder { /* Firefox 19+ */
      @include modal-input;
    }
    :-ms-input-placeholder { /* IE 10+ */
      @include modal-input;
    }
    :-moz-placeholder { /* Firefox 18- */
      @include modal-input;
    }

    button {
      padding: .5rem 2.5rem;
    }
  }

  .modal-footer {
    border-top: none;
    padding: 2rem 1rem 1.5rem;
    font-size: 1rem;
  }
}

.modal-backdrop {
  background-color: white;
}

.modal > .modal-dialog > button.close {
  position: absolute;
  right: 5px;
  top: 2px;
  cursor: pointer;
}


.modal.show .modal-dialog {
  pointer-events: auto;
  @include media-breakpoint-up(md) {
    margin-top: 5rem;
  }

  &.modal-xl {
    @include media-breakpoint-between(sm, lg) {
      max-width: 95%;
    }
  }

  &.quotation-modal {
    @media screen and (max-width:600px) {
      margin: 6rem 2rem 2rem;
    }
    padding: 0;
    background: none;
    border: none;
  }
}

.modal-footer {
  justify-content: center;
}

// SweetAlert 2
///////////////
.swal2-container {
  z-index: 10000 !important;
}

.swal2-popup.swal2-modal.swal2-show {
  height: 400px;
  max-height: 90%;
  width: 700px;
  max-width: 90%;
}

#quotation-modal-job-offer-form .form-group {
  text-align: center;
}

// Full screen modal
////////////////////
.modal-dialog {
  &.modal-full-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: none;
    max-height: none;
    margin: 0 !important;
    border-radius: 0;
    .modal-content {
      width: 100%;
      height: 100%;
      border-radius: 0;
      overflow: auto;
      justify-content: center;
      align-items: center;
    }
  }

  &.modal-mobile-full-screen {
    @include media-breakpoint-down(sm) {
      position: fixed !important;
      top: 0;
      left: 0;
      width: 100vw !important;
      height: 100vh !important;
      max-width: none !important;
      max-height: none !important;
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0;

      .modal-content {
        width: 100%;
        height: 100%;
        border-radius: 0;
        overflow: auto;
        justify-content: center;
        align-items: center;
      }

      button.close {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        border-radius: 0 0 25px 0;
        opacity: 1;
        color: $white;
        background-color: $electric-blue;
        margin: 0;
        text-shadow: none;

        i {
          font-size: 1.5rem;
        }
      }
    }
  }

  &.modal-height-90 {
    max-height: 90%;
    overflow: auto;
    padding: 5px;
    @extend .custom_scroll_bar;
  }
}

// Service deal modal
/////////////////////
.service-deal-modal {

  height: 100%;

  .modal-header {
    justify-content: center;
    padding-bottom: .5rem;
  }

  .modal-body {
    padding-top: .5rem;
  }

  .modal-footer {
    padding-top: 1rem;
  }

  color: $black;

  .btn {
    color: $white !important;

    &.btn-black:hover {
      background-color: black;
    }
  }

  .service-deal-modal-subcategory {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 20px;
      margin-right: 2rem;
      font-size: 1.5rem;
    }
  }

  .service-deal-modal-info-icon {
    position: absolute;
    right: -40px;
    height: 25px;
    width: 25px;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    border-radius: 50px;
    cursor: pointer;
  }

  &-title-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      font-size: 40px;
    }

    &-content {
      font-size: 1.125rem;
      font-weight: bold;
      text-align: center;
    }
  }

  &-block {
    margin: 0.5rem 0;

    &-title {
      font-size: 18px;
      font-weight: bold;
    }

    &-content {
      color: $black;
      font-weight: 500;
      margin-left: 0.5rem;
    }

    &-total {
      font-size: 25px;
      font-weight: bold;
      text-decoration: underline;
    }

    &-border-elem {
      border: 2px solid;
      padding: 0.5rem;
    }
  }

  .collapse_service_deal_details {
    padding: 1rem;
    border: 2px solid;
    border-radius: 1rem;
    &_title {
      font-size: 1.25rem;
      font-weight: bold;
    }
    &_item {
      text-align: start;

      &_help {
        font-size: 0.75rem;
        line-height: 0.75rem;
      }
    }

    .service-deal-modal-info-icon {
      right: 0;
      border: 1px solid;
    }
  }

  &.service-deal-modal-success {
    .service-deal-modal-title-block {
      color: $green;
    }
  }

  &.service-deal-modal-reject {
    .service-deal-modal-title-block {
      color: $red;
    }
  }

  &.service-deal-modal-presta {
    .service-deal-modal-mission-type, .service-deal-modal-block-title,
    .service-deal-modal-block-total, legend, label:not([for=id_date_day],[for=id_start_date_day]),
    .service-deal-modal-block-border-elem, .service-deal-modal-subcategory-name {
      color: $presta-color;
    }

    .service-deal-modal-subcategory i, .service-deal-modal-info-icon {
      color: white;
      background-color: $presta-color;
    }
  }

  &.service-deal-modal-part-emp {
    .service-deal-modal-mission-type, .service-deal-modal-block-title,
    .service-deal-modal-block-total, legend, label:not([for=id_date_day],[for$=start_date_day]),
    .service-deal-modal-block-border-elem, .service-deal-modal-subcategory-name {
      color: $part-emp-color;
    }

    .service-deal-modal-subcategory i, .service-deal-modal-info-icon, .collapse_service_deal_details {
      color: $white;
      background-color: $part-emp-color;
    }

    .custom-radio label::after {
      filter: invert(69%) sepia(24%) saturate(614%) hue-rotate(144deg) brightness(90%) contrast(90%);
    }
  }

  &.service-deal-modal-stripe-return {
    .modal-body {
      font-size: 0.875rem;
      font-weight: bold;
      text-align: center;
      .service-deal-modal-title-block {
        font-size: 1.125rem;
        font-weight: bold;
        i {
          font-size: 5rem;
        }
      }
    }
  }
}

.service-deal-modal:not(.service-deal-form-modal, .service-deal-modal-stripe-return) {
  .modal-body {
    margin: auto;
    text-align: start;
  }

}

.service-deal-form-modal {
  .service-deal-modal-block {
    &-title, legend, label.form-control-label:not([for=id_date_day],[for$=start_date_day]) {
      font-size: 1rem;
      font-weight: normal;

      &::after {
        content: " *";
        color: red;
        font-weight: 900;
      }
    }
  }

  .hourly-price-field {
    .form-group {
      margin-bottom: 0.5rem;
    }
  }

  label[for=id_not_adhoc_mission_date], label[for=id_adhoc_mission_date] {
    display: none;
  }

  .not_adhoc_mission_hourly_price_field {
    display: flex;
    align-items: center;
    .form-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.5rem;
    }
  }

  input[name=frequency_input_0] {
    margin-bottom: 0.75rem;
  }

  input[name$=_hourly_price], input[name=duration] {
    width: 110px;

    &.is-invalid {
      width: 140px;
      & + .form-control-feedback {
        display: none;
      }
    }
  }

  label[for=id_not_adhoc_mission_hourly_price] {
    width: 100%;
  }
}

///Modal-connect
#signin-modal-form {
  .btn {
    font-size: 1.125rem;
  }
  .connect {
    .form-group {
      margin-bottom: 1rem;
      .form-control {
        &::placeholder {
          color: $testimony-blue-op50 !important;
        }
        .password_display_toggle {
          margin-right: .5rem;
        }
      }
      .form-check-label {
        display: flex;
        justify-content: center;
      }
    }
    .password-reset-link {
      font-size: .875rem;
    }
  }
}