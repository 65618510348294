.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.top-right {
  top: 0;
  right: 0;
}

.top-left {
  top: 0;
  left: 0;
}

.bottom-right {
  bottom: 0;
  right: 0;
}

.bottom-left {
  bottom: 0;
  left: 0;
}