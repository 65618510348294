.alert.phone-premium-rate {
  margin-top: 1rem;
  z-index: 100;
  position: relative;

  a:hover {
    text-decoration: none;
  }

  .phone-layout {
    max-width: 428px;
    height: 56px;
    position: relative;
    display: flex;
    border-radius: 4px;
    overflow: hidden;

    .price, .phone {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }

    .price {
      color: $white;
      font-size: 13px;
      background-color: #67686a;
      position: relative;
      text-align: left;
      padding-left: .5rem;

      &:before {
        content:'';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 0 15px 15px;
        border-color: transparent transparent transparent white;
        left: 0;
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }

    .phone {
      color: #91919b;
      font-size: 21px;
      background-color: white;

      @include media-breakpoint-only(lg) {
        font-size: 20px;
      }
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    @include media-breakpoint-down(xs) {
      display: block;
      height: auto;
    }
  }

  .delay {
    font-size: .8rem;
  }
  .sva {
    font-size: .7rem;
  }
}

.alert.alert-social-auth {
  @each $color, $value in ("danger", "success") {
    &.#{$color} {
      @include alert-variant(theme-color-level($color, -10), theme-color-level($color, -9), theme-color-level($color, 6));
    }
  }
}

.grouped-links-list {
  h3 {
    margin-top: .5rem;
  }
}

.w-content {
  width: fit-content;
}

.alert-popup {
  position: fixed;
  width: 100%;
  z-index: $zindex-fixed;
  top: 100px;
  .alert {
    margin: auto;
    max-width: 500px;
  }
}

.pagination {
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.75rem;

  .page-item.active {

    .page-link {
      box-shadow: 0 5px 10px #00000029;
    }

    &:first-child, &:last-child {
      display: none;
    }
  }

  .page-link {
    margin-left: 5px;
    display: inline-block;
    min-width: 34px;
    height: 34px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    border-radius: 500px !important;

    &:hover {
      box-shadow: 0px 5px 10px #00000029;
    }

    i {
      font-size: 10px;
    }
  }
}
.page-link {
  display: initial;
}

span[data-url] {
  cursor: pointer;
}
span.page-link.btn-block {
  width: 100%;
  display: block;
}

.mb-6 {
  margin-bottom: 6rem;
}

[data-broad-link] {
  cursor: pointer;
}

.rating-mini-site {
  font-weight: 800;
  font-size: 1rem;
}
.review-rating {
  display: block;
}
.rating-stars {
  display: inline-block;
  width: 100%;
  max-width: 180px;
  color: $yellow;

  img {
    width: 15%;
    margin: 2%;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}
.service-ratings {
  span.rating-stars {
    width: 60%;
    display: block;
    margin: auto;
  }
}

.bubble-rating {
  display: inline-block;
  width: 100%;
  max-width: 130px;

  span {
    border-radius: 50%;
    box-shadow: $default-shadow;
    background: $white;
    width: 15%;
    margin: 2%;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    &.bubble-rating-full {
      background-color: $electric-blue;
    }

    &.bubble-rating-half {
      background: linear-gradient(90deg, $electric-blue 50%, $white 50%);
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
button.btn-review-modal {
  background: #1E43CB 0 0 no-repeat padding-box;
  box-shadow: $default-shadow;
  border-radius: 31px;
  opacity: 1;
  text-align: center;
  font: normal normal medium 15px/10px Montserrat;
  letter-spacing: 0;
  color: #FFFFFF;
  text-transform: uppercase;
}

.list-item-rate {
  background-color: $gray-100;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;

  .review-comment {
    background-color: $white;
    padding: 0.5rem;
    font-style: italic;

    p {
      padding: 0.5rem;
      margin: 0;
    }
  }
}

.button-add-reviews {
  @include media-breakpoint-down(md) {
    padding: 10px !important;
  }
}
.review-card, .review-answer-card {
  color: $black;
  padding: 5%;
  border: 1px solid #D9E0FB;
  min-height: 50px;

  .review-card-content {
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  .review-card-img-block {
    figure {
      width: 150px;
      height: 150px;

      @include media-breakpoint-down(md) {
        width: 120px;
        height: 120px;
      }

      @include media-breakpoint-down(sm) {
        width: 60px;
        height: 60px;
      }
      border: none;
      box-shadow: $default-shadow;
    }
  }
  .review-card-title-block {
    font-weight: 300;
    h3 {
      font-size: 1rem;
      color: $black;
      @include media-breakpoint-down(md) {
        font-size: 1rem;
      }
    }
    @include media-breakpoint-down(md) {
      font-size: .9rem;
    }

    &-infos {
      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }
    }
    .review-date {
      font-size: .8rem;
    }
  }

  .review-card-comment-block {
    margin-top: 1rem;
    line-height: 1.2rem;
  }

  .review-card-action-block {
    @include media-breakpoint-up(lg) {
      > div {
        padding: 0;
      }
      margin-right: 1%;
    }

    .btn {
      font-weight: normal;
      text-transform: uppercase;
      @include media-breakpoint-down(md) {
        font-size: 10px;
        padding: 6px 12px;
      }
    }

    .btn-black:hover {
      background-color: $white !important;
      border: 1px solid $testimony-blue;
      color: $testimony-blue !important;
    }

    .btn-secondary:hover {
      background-color: $white !important;
      border: 1px solid $black;
      color: $black !important;
    }
  }

  .review-card-rate {
    &-label {
      font-size: 1rem;
      font-weight: bold;
    }

    @include media-breakpoint-up(lg) {
      margin-right: 5%;
    }

    @include media-breakpoint-down(md) {
      max-width: 140px;
      justify-content: end;
      text-align: right;
      display: flex;
      margin-bottom: 1rem;

      &-label {
        margin-left: 1rem;
      }
    }
  }
  .review-card-subcategory-block {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.2rem;
  }
}

.review-slider {
  overflow-x: auto;
  padding-bottom: 1rem;
  display: flex;
  .spinner-aladom {
    margin: auto;
  }
  .review-card {
    display: inline-block;
    background-color: $white;
    width: 400px;
    position: relative;
  }
  #accordion-answer {
    position: relative;
  }
  .review-answer-card {
    background-color: #D9E0FB;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .review-card,.review-answer-card {
    padding: unset;
    border-radius: 20px;
  }
}

a.link-unstyled {
  color: inherit;
  text-decoration: inherit;

  .rating-stars {
    width: 100px;
  }
}

.text-wrap {
  white-space: normal;
}

.table {
  thead th {
    vertical-align: middle;
  }
}

.billing-candidate-bloc {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  border: 2px solid $azure-blue;
  margin-bottom: .5rem;

  .candidate-number {
    display: grid;
    height: 100%;
    align-items: center;
    text-align: center;
    font-size: 4rem;
    color: $azure-blue;
  }
}

.text-inherit {
  white-space: inherit;
}

.grayscale-image {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

#aladom-box-10 .form-control-label {
  color: white;
}

#map {
  position: relative;
}

#map-info {
  position: absolute;
  z-index: 1;
  background-color: white;
  padding: .5rem;
  border-radius: 10px;
  text-align: center;
  bottom: 1rem;
  left: 1rem;
  box-shadow: 0 6px 14px 0 #0006;
  width: 160px;

  p {
    margin-bottom : .5rem;
  }

  .map-info-picture {
    border-radius: 50%;
    background-color: white;
    overflow: hidden;
    width: 100px;
    height: 100px;
    border: 1px solid #979797;
    display: flex;
    position: relative;
    margin: auto auto .5rem;

    img {
      width: 70px;
      height: auto;
      margin: auto;
      top: 50%;
      bottom: 50%;
    }
  }
}

.box.shadowed {
  box-shadow: $default-shadow;
}

.min-w-200 {
  min-width: 200px;
}

.btn.switch-btn {
  background-color: #868e95;
  border-color: #868e95;
  color: white;
  &.activated {
    background-color: $testimony-blue;
    border-color: $testimony-blue;
    box-shadow: 0 0 0 0.2rem rgba(0, 136, 255, 0.2);
    &:hover {
      background-color: #003bff;
    }
  }
  &:hover {
    background-color: #545b62;
    border-color: #545b62;
  }
}

.show {
  .btn-push {
    span {
      display: block;
    }
  }
}

.btn-push {
  width: 50px;
  height: 20px;
  border-radius: 500px;
  box-shadow: $default-shadow;
  background-color: $white;
  padding: 5px 12px;
  border: none;

  span {
    display: none;
    width: 26px;
    height: 10px;
    border-radius: 500px;
    background-color: $black;
  }

  &:hover {
    span {
      display: block;
    }
  }
}

.btn-push-presta {
  span {
    background-color: $electric-blue;
  }
}

.btn-push-part-emp {
  span {
    background-color: $cyan;
  }
}

.info-icon {
  display: inline-block;
  text-align: center;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50%;
  border: 1px solid $black;
  color: $black;
  font-size: 12px;
  cursor: pointer;
}

.accordion {
  .btn-link {
    color: unset;
    font-weight: bold
  }
  i.fa {
    font-size: 1.5rem;
  }
  @include media-breakpoint-down(sm) {
    i.ala-chevron-down {
      font-size: small;
    }
  }
}

.btn-part-emp, .btn-cyan {
  color: white !important;
  background-color: $cyan !important;
  box-shadow: $default-shadow;
}

.btn-presta {
  background-color: $electric-blue !important;
  color: white;
  &:hover {
    color: white;
  }
}

.btn-pass {
  color: $white;
  background-color: #D6D6D6;
  box-shadow: $default-shadow;
  font-size: 17px;

  &:hover {
    background-color: white;
    color: #001835 !important;
    border-color: #001835;
    border-width: 1px;
    box-shadow: none;
  }
}

.subcategory-badge {
  font-size: 50px;
  width: 80px;
  height: 80px;
  padding: 15px;
  box-shadow: $default-shadow;
  border-radius: 50%;
}

.custom_scroll_bar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    cursor: pointer;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-800;
    cursor: pointer;
  }
}

