form {
  // Stripe form
  &.ElementApp.is-empty {
    font-size: 18px;
  }

  div.form-group {
    .select2-container {
      flex: 1 1 auto;
      min-width: auto;
      width: 100% !important;

      .select2-selection--single {
        height: 38px;
      }

      .select2-selection--single .select2-selection__rendered {
        &#select2-id_search_city-container, &#select2-id_header_search_city-container {
          line-height: inherit;
        }
      }
    }

    .input-group .select2-container--default .select2-selection--single {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    .select2-container--default .select2-selection--single {
      border: $input-btn-border-width solid #9b9b99;

      .select2-selection__arrow b {
        top: 60%;
      }
    }

    &.has-danger {
      .select2-selection {
        border-color: theme-color('danger');
      }
    }

    &.full-form-group {
      width: 100%;
      max-width: unset;
      margin-bottom: 0;

      .select2-container {
        max-width: unset;
      }

      & + .form-group {
        margin-right: unset;
        margin-bottom: 0;
        display: flex;

        @include media-breakpoint-down(sm) {
          width: 100%;
          max-width: unset;
          padding-top: 1rem;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      button[type="submit"] {
        width: 100%;
      }
    }
  }

  fieldset legend, label.form-control-label {
    font-size: .875rem;
    font-weight: 500;
    color: $black;
  }

  label.required-label:after, legend.required-label:after {
    content: ' *';
    color: red;
  }

  span.custom-required:after {
    margin-left: 0.5rem;
    content: '*';
    color: red;
  }

  label.form-control-label + .form-text, fieldset legend + .form-text {
    margin-top: -.5rem;
    margin-bottom: .5rem;
  }

  .form-check {
    margin-bottom: 0;
    padding-left: 0;

    &.form-group {
      margin-bottom: 1rem;
    }
  }

  ul.errorlist, ul.custom-errorlist {
    color: theme-color('danger');
    list-style: none;
    padding-left: 0;

    li {
      border-left: 3px solid theme-color('danger');
      padding-left: .5rem;
      margin-top: .5rem;
    }
  }

  .alert-danger ul.errorlist, .alert-danger ul.custom-errorlist {
    color: inherit;

    li {
      border-left: none;
      padding-left: 0;
      margin-top: 0;
    }
  }

  .banwords-textarea {
    min-height: 100px;
    max-height: 300px;
    overflow-y: auto;
    display: block !important;

    .banword {
      color: theme-color('danger');
    }
  }

  input[type="text"], input[type="email"]{
    font-size: .875rem;
    font-weight: 500;
  }

  textarea.invalid {
    border-color: $light-red;
  }

  .textarea-count {
    position: absolute;
    right: 10px;
    bottom:-10px;
    transform: translate(-50%, -100%);
    &.invalid {
      color: $light-red;
    }
  }
}

form .select2-selection--single .select2-selection__rendered {
  padding-top: 4px;
}
form .select2-selection--single.custom-select.form-control .select2-selection__rendered {
  padding-top: 0;
}

div.form-inline form.offer-search-form, div.job-offer-search-form, .offer-search form.offer-search-form {
  #select2-id_search_city-container, #select2-id_header_search_city-container {
    padding-top: 0 !important;
    padding-left: 0;
  }
}
#select2-id_city-container {
  padding-top: 0;
  padding-left: 0;
}

input[data-field-type="simplephone"].form-control {
  padding-right: calc(1.5em + .75rem)!important;
}

span.select2-container.select2-container--default.select2-container--open.subcategory-select2 {

  &:not(.adapt) {
    left: 5% !important;
    right: 5% !important;

    & > .select2-dropdown {
      width: 100% !important;

      & > .select2-results > ul.select2-results__options > li.select2-results__option {
        flex: 20% 1;
        max-width: 20%;
      }
    }
  }

  &.adapt {
    & > .select2-dropdown > .select2-results > ul.select2-results__options > li.select2-results__option {
        min-width: 33%;
        max-width: 33%;
    }
  }

  & > .select2-dropdown {

    & > .select2-results > ul.select2-results__options {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      font-size: $small-font-size;
      font-weight: 500;
      max-height: 400px;

      & > li.select2-results__option {
        padding: .5rem;

        .select2-results__group {
          background-color: $gray-100;
          text-align: center;
          font-weight: normal;
          text-transform: uppercase;
          color: $black;
        }

        & > ul > li {
          padding: 0 0 0 1rem;
          color: $blue;
          font-family: $font-family-icon;

          &.select2-results__option--highlighted {
            background-color: $gray-200;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &.adapt > .select2-dropdown > .select2-results > ul.select2-results__options > li.select2-results__option {
      max-width: 50%;
    }
  }

  @include media-breakpoint-down(sm) {
    &.adapt > .select2-dropdown, &:not(.adapt) > .select2-dropdown {
      & > .select2-results > ul.select2-results__options {
        display: inherit;

        & > li.select2-results__option {
          max-width: 100%;
          padding: 0;
        }
      }
    }
  }
}

span.select2-container.select2-container--default.select2-container--open {
  z-index: 3000;
  max-width: 100%;
}

.select2-selection__placeholder, .select2-selection__rendered, select.custom-select {
  font-family: $font-family-icon;
  font-weight: 500;
  font-size: .875rem;
  color: $black;
}

form#signin-modal-form {
  .modal-footer {
    justify-content: center;
  }
}

.password_display_toggle {
  right: 5px;
  position: relative;
  z-index: 100;
  top: -30px;
  font-size: 20px;
  float: right;
  &.password-right {
    float: revert;
    right: 10px;
    left: 220px;
    @include media-breakpoint-down(lg) {
      left: 190px;
    }
    @include media-breakpoint-down(md) {
      left: 210px;
    }
    @media (max-width: 400px) {
      left: 72%;
    }
  }
}

.form-box {
  background-color: $gray-100;
  padding: 1rem;
}
.custom-file {
  cursor: pointer;
}

.custom-file-label {
  border: none;
  box-shadow : $default-shadow;
  font-weight: 300;
  color: $black;
  &.candidacy-cv {
    font-size: 1rem;
    letter-spacing: 0;
    margin: auto;
  }
}

.custom-file-label::after {
  content: 'Parcourir';
  border: 1px solid $black;
  border-radius: .25rem;
  height: 100%;
  font-weight: 300;
  color: $black;
}

label.candidacy-cv.custom-file-label::after {
  content: url("/ui/static/icons/png/white/light-download.png");
  background: #000000;
  color: white;
  border-radius: 0 12px 12px 0;
  box-shadow: 0 3px 6px #00000029;
  padding: 0.2rem 3rem;
}
#candidacy-connect-account-form {
  div.container div.row a {
    margin-top: -2rem;
  }
}
.candidacy-popover {
   border-radius: 24px;
   border: 3px solid #000;
  .popover-header {
    background: black;
    border-radius: 20px 20px 0 0;
    color: $white;
    padding: 1rem;
  }
  .popover-body {
    background: black;
    color: $white;
    padding: 0;
    border-radius: 0 0 20px 20px;
    .top-popover {
      padding: 1rem;
    }
    .bottom-popover {
      padding: 1rem;
      background: $white;
      color: black !important;
      border-radius: 0 0 20px 20px;
    }
  }
}
.job-popover {
  border-radius: 24px;
  border: 3px solid #000;
  .popover-header {
    color: $black;
    border-radius: 20px 20px 0 0;
    background: $white;
    padding: 1.3rem;
  }
  .popover-body {
    color: $black;
    background: $white;
    padding: 0;
    border-radius: 0 0 20px 20px;
    .top-popover {
      padding: 1rem;
    }
    .bottom-popover {
      padding: 1rem;
      color: $white;
      background: $black !important;
      border-radius: 0 0 20px 20px;
    }
  }

}

div.display-table {
  display: table;
  height: 100%;
}
div.vertical-middle {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.custom-select {
  -webkit-appearance: none;
}

.form-control {
  font-size: .875rem;
  font-weight: 500;
}

.custom-radio {
  margin-top: 8px;
}

// Button file upload
/////////////////////
.custom-file-control {
  background-color: $gray-600;
  color: $white;
}

// DateTimePicker
/////////////////
.datetimepicker {
  font-family: sans-serif;

  table.table-condensed {
    border-collapse: initial;
  }
}

.date-select {
  max-width: 500px;
  margin-top: 0 !important;

  .date-select-item {
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
}

[data-action="form-group-toggle"] {
  cursor: pointer;
}

// Teach tunnel form
#teach-step1-form {
  li {
    list-style-type:none;
  }
  .form-group {
    margin-bottom: 1rem;
  }
  @include media-breakpoint-up(lg) {
    fieldset.form-group {
      width: 50%;
      float: left;
    }
    div.form-group {
      clear: both;
    }
  }
}

// Childcare tunnel form
#childcare-step1-form {
  @include media-breakpoint-down(md) {
    .form-check {
      display: inline;
    }
  }
}

// training forms
#training-request-create-form, #training-info-form {
  #page-modal-label {
    color: $azure-blue;
    font-weight: 700;
    line-height: normal;
    font-size: 2rem;
  }
}

// Stripe Forms
.StripeElement:not(.form-control) {
  height: 40px;
  padding: 10px 12px;

  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.select2-container--default .select2-selection--multiple {
  height: auto;
}

.card.checked {
    background-color: #08f3;

    .checked-card-overlay {
        position:absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
    }
}

.custom-control-label.big-checkbox:before,
  .custom-control-label.big-checkbox:after {width: 20px; height: 20px;}

.form-check-inline {
  @include media-breakpoint-down(md) {
    display: block;

    .custom-control {
      margin-top: 1.5rem;
    }
  }
}

// form materialize
body {

  h2.part-emp {
    font-size: 2.4rem;
    color: $cyan;
    font-weight: 700;
    text-transform: none;
  }

  form div.form-control-materialize {
    @include media-breakpoint-up(lg) {
      max-width: 700px;
    }
    margin: 0 auto 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-control, .custom-radio {
      margin-top: 10px;
    }

    .select2-container {
      display: flex;
      justify-content: center;

      .selection {
        min-width: 150px;
      }
    }

    label.form-control-label-materialize {
      @include media-breakpoint-down(md) {
        font-size: 20px !important;
      }
      font-size: 25px !important;
    }

    .materialize-widget {
      max-width: none;
    }

    .tox.tox-tinymce {
      width: 100%;
    }
  }

  .form-control-materialize {
    @include media-breakpoint-up(lg) {
      max-width: 700px;
    }
    margin: 0 auto 2rem;
    text-align: center;

    legend {
      @include media-breakpoint-down(md) {
        font-size: 20px !important;
      }
      font-size: 25px !important;
      font-weight: 500;
      margin-bottom: 1.5rem;
    }
  }

  form {
    button:hover {
      background-color: white;
      border-width: 1px;
      box-shadow: none;
    }

    &.service-form {
      .custom-control-label, legend, label.form-control-label-materialize {
        color: $electric-blue;
      }

      .custom-control-input:checked ~ .custom-control-label::before, button {
        color: $white;
        border-color: $electric-blue;
        background-color: $electric-blue;
      }

      button:hover {
        color: $electric-blue !important;
        border-color: $electric-blue;
        background-color: $white;
      }
    }

    &.job-form:not(#quotation-modal-job-offer-form) {
      .custom-control-label, legend, label.form-control-label-materialize {
        color: $cyan;
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        color: $white;
        border-color: $cyan;
      }

      .btn {
        color: $white;
        background-color: $cyan;
        &:hover {
          color: $cyan;
          background-color: $white;
          border-color: $cyan;
        }
      }

      .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 8 8'%3E%3Ccircle r='4' cx='50%25' cy='50%25' fill='%2351ADB9'/%3E%3C/svg%3E") !important;
      }

    }
  }

  .custom-control-label {
    &:before {
      border-radius: 20px;
    }
  }
}

.part-category-card {
  width: 150px;
  height: 150px;
  box-shadow: 0 5px 10px #0000004D;
  border: 2px solid transparent;
  cursor: pointer;
  background-color: $white;
  border-radius: 20px;
  &:hover {
    box-shadow: inset 2px 2px 20px #0000004D;
    border: 2px solid #57B0BB;
    transition-duration: 600ms;
  }

  .part-category-icon {
    display: block;
    font-size: 3.8rem !important;
    margin: 15px 0 -15px;
  }

  .part-category-label {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.2;
    padding: 5px;
  }
}

.category-selected {
  box-shadow: inset 2px 2px 20px #0000004D;
  border: 2px solid #57B0BB;
  margin-left: 12px;
}
#service-offer-create-form .category-selected, #service-offer-update-form .category-selected {
  border-color: $presta-color;
}

.reset-cat-select {
  font-size: 1.5rem;
  margin: auto 0;
  padding-bottom: 15px;
  cursor: pointer;
}

.custom-control-inline.inline-btn {
  padding-left: 0 !important;

  input:checked ~ label {
    @include btn-color($testimony-blue);
    text-transform: initial;
    border-radius: 40px;
  }

  label {
    @include btn-color($gray-500);
    padding: 0.4rem 1.5rem;
    border-radius: 40px;
    text-transform: initial;
    cursor: pointer;
  }

  .custom-control-label:before, .custom-control-label:after {
    content: none;
  }
}

fieldset legend, .form-control-label {
  color: $black;
  font-weight: 500;
  width: calc(100% - 1.5rem);
  letter-spacing: normal;
}

fieldset.form-group, fieldset.form-row {
  margin-top: 20px;
}

.form-group, .form-row {
  position: relative;
  margin-top: 0;

  legend, .form-control-label:not(.floating-label) {
    font-size: .85rem;
    &.legend-lg {
      font-size: 1rem;
    }
  }

  .form-control-label.floating-label {
    position: absolute;
    margin: 0;
    top: calc(50% - 14px);
    left: 15px;
    transition: font-size 200ms, top 200ms, left 200ms;
  }

  .form-control:focus ~ .form-control-label.floating-label,  /* Input has focus */
  .form-control:not(:placeholder-shown) ~ .form-control-label.floating-label  /* Input has a value */  {
    font-size: .85rem;
    top: -1.5rem;
    left: 0;

    &:after {
      position: relative;
    }
  }
}

.form-control, .tox-tinymce, .custom-select {
  box-shadow : 0 3px 6px #00000029;
  border: none;
  border-radius: 10px;
  color: $black !important;
  font-weight: 500;
}

input.form-control, div.custom-file {
  max-width: 260px;
  &.candidacy-cv {
    max-width: unset;
  }
}
div.custom-file {
  max-width: 400px;
  &.candidacy-cv {
    max-width: unset;
  }
}
input.form-control, div.custom-file {
  width: 100%;

  .form-row & {
    max-width: unset;
  }
}

.input-block {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.custom-layout, .modal {
  input.form-control, form .select2-container--default .select2-selection--single {
    max-width: unset;
  }

  .input-block {
    display: block;
  }
}

select.form-control {
  display: block;
  width: auto;
  text-align: center;
  max-width: 100%;
}

.form-row select.form-control, .form-row input.form-control {
  max-width: unset;
  width: 100%;
}

.custom-control {
  padding-left: 2.5rem;
}

.custom-checkbox {
  margin: 1rem;
}

.custom-radio .custom-control-label:before,  .custom-radio .custom-control-label:after {
  border-radius: 50%;
}

.custom-control-label {
  color: $black;
  font-weight: 500;

  &:before, &:after {
    box-shadow : $default-shadow;
    border: none;
    top: -.1rem;
    left: -2.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.custom-control-input:checked:not(:disabled) ~ .custom-control-label::before {
  background-color: $testimony-blue;
  border-color: $testimony-blue;
}

.custom-radio .custom-control-input:checked:not(:disabled) ~ .custom-control-label::before {
  background-color: white;
  border-color: white;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-radio .custom-control-input:not(:disabled):checked ~ .custom-control-label::after {
  background-image : url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 8 8'%3E%3Ccircle r='4' cx='50%25' cy='50%25' fill='%232653fb'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::after {
  background-image: none;
}

form div.form-group .select2-container--default .select2-selection--single {
  border: none;
  box-shadow: 0 3px 6px rgba(0,0,0,.1607843137254902);
  border-radius: 10px;
  width: 100%;
  max-width: 260px;
  text-align: center;
}

form label.required-label::after, form legend.required-label::after {
  font-weight: 900;
}

form label.required-label.floating-label::after, form legend.required-label.floating-label::after {
  position: absolute;
  right: 0;
}

form input.form-control:not(.custom-control-input), form input.form-control:focus, form input.form-control::placeholder {
  color: $medium-grey;
  font-weight: 500;
  opacity: 1;
  font-size: 1rem;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 255, 0.2) !important;
  color: $black;
}

form .select2-container--default .select2-selection--single {
  border: none;
  box-shadow: 0 3px 6px rgba(0,0,0,.1607843137254902);
  border-radius: 10px;
  width: 100%;
  max-width: 320px;
  text-align: center;
  min-height: 42px;
}

.form-row .select2-container--default .select2-selection--single {
  max-width: unset;
}

form div.form-group .select2-container--default .select2-selection--single {
  border: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: $black;
  font-weight: 500;
}


form label.required-label::after, form legend.required-label::after {
  font-weight: 900;
}

form label.required-label.floating-label::after, form legend.required-label.floating-label::after {
  position: absolute;
  right: 0;
}

.task-list {

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked ~ label {
    color: $black;
    border: 2px solid $black;
    font-weight: 500;
  }

  label {
    color: #707070;
    border: 1px solid #707070;
    letter-spacing: 0;
    border-radius: 8px;
    padding: .4rem .4rem .4rem 1.7rem;
    font-size: .75rem;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    line-height: .9rem;
    text-align: center;
    padding-right: .4rem;
    background-color: white;
    position: relative;

    @include media-breakpoint-down(md) {
      padding-left: .4rem;
    }

    &:before {
      content: "+";
      font-size: 2.5rem;
      position: absolute;
      left: 7px;

      @include media-breakpoint-down(md) {
        font-size: 1.5rem;
      }
      font-weight: 300;
    }
  }

  .task-input {
    margin: 0;
    width: 24%;
    min-height: 80px;
    padding: .5rem;

    .unbounce-card & {
      width: 50% !important;
    }

    @include media-breakpoint-down(md) {
      width: 33%;
    }
    @include media-breakpoint-down(sm) {
      width: 50%;
    }
  }

}

div.formset-form-top-level.display-flex {
  &.has-errors {
    display: flex !important;
  }
}


legend.task-legend {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}

div.form-group.padding-btn-delete {
  padding-top: 30px;
  padding-left: 10px;
}

.form-check-inline {
  @include media-breakpoint-down(md) {
    display: block;

    .custom-control {
      margin-top: 1.5rem;
    }
  }
}

.subcategory-multi-choice {
  .home-text-search.form-group {
    margin-bottom: .5rem;
    margin-top: .5rem;
  }
  .category-search.form-group {
    margin-top: 0;
  }
}

.subcategory-multi-results {
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-down(md) {
    justify-content: center;
  }

  .more-scats {
    border: none;
    background: transparent;
    font-size: 1.2rem;
    font-weight: 600;
    color: $black;
    padding: 1rem;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 136, 255, 0.2);
    }
  }

  .card {
    width: 140px;
    height: 140px;
    border: 2px solid $black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    color: #323434;
    font-weight: 700;
    font-size: .8rem;
    line-height: .9rem;
    padding: .7rem;
    background-color: white;
    position: relative;
    letter-spacing: normal;
    cursor: pointer;
    margin: .5rem;
    max-width: calc(50% - 1rem);

    &:focus {
      border-color: $testimony-blue;
      outline: 0;
    }
    i.ala {
      font-size: 2.5rem;
      padding: .8rem;
      margin: auto;
    }
    i.ala-close {
      position: absolute;
      font-weight: bolder;
      left: 12px;
      top: 12px;
    }
  }
}

.job_offer_frequency_input {
  input, select {
    display: inline;
  }
  select {
    width: auto !important;
  }
  input[type=number] {
      width: 70px;
    &.is-invalid {
      width: 100px;
    }
  }
  &_text {
    font-size: 16px;
    margin: 0 10px;
  }
}

.form-group.file-input-group {
  .form-text, .form-text span { // file help texts
    color: $black;
    font-size: .85rem;
    letter-spacing: normal;
    font-weight: 300;
  }
}

// Multi city select autocomplete
/////////////////////////////////
.city-multi-choice {
  .city-search {
    box-shadow: none !important;
    background: none !important;
    padding-bottom: 1rem !important;
  }
}

// City radius range slider
///////////////////////////
.city-radius-container {

  .city-radius-display-result {
    color: $light-red;
  }

  input[type=range].radius-slider {

    -webkit-appearance: none;
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));

    &, &:focus {
      outline: none !important;
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 1rem;
      height: 1rem;
      border: 0.4rem solid $light-red;
      border-radius: 0.5rem;
      background: $gray-100;
      transform: translateY(calc(-50% + 1px));
    }

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      height: 3px;
      background: linear-gradient($light-red,$light-red) 0/var(--sx) 100% no-repeat, $gray-400;
    }

    &::-webkit-slider-thumb:hover, &::-webkit-slider-runnable-track:hover {
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 0.2rem;
      height: 0.2rem;
      border: 0.4rem solid $light-red;
      border-radius: 0.5rem;
      background: $gray-100;
    }

    &::-moz-range-track {
      height: 3px;
      background: linear-gradient($light-red,$light-red) 0/var(--sx) 100% no-repeat, $gray-400;
    }

    &::-moz-range-thumb:hover, &::-moz-range-track:hover {
      cursor: pointer;
    }

    &::-ms-fill-upper, &::-ms-fill-lower {
      background: transparent;
      border-color: transparent;
    }

    &::-ms-thumb {
      width: 1rem;
      height: 1rem;
      border-radius: 0.5rem;
      background: $light-red;
      border: none;
    }

    &::-ms-track {
      height: 3px;
      background: linear-gradient($light-red,$light-red) 0/var(--sx) 100% no-repeat, $gray-400;
    }
  }
}

form.job-offer {
  fieldset.form-group, fieldset.form-row {
    margin-top: unset;
  }
  .custom-checkbox {
    margin: 0 1rem 1rem 0;
  }
  div.job-offer-box-form {
    border: 1px solid black;
    border-radius: 10px;
  }
}

// daterangepicker
//////////////////
.daterangepicker {
  border-radius: 19px;
  box-shadow: $default-shadow;
  border: none;
  overflow: hidden;

  button.applyBtn, button.cancelBtn, button.eraseBtn {  /* 3 btns of dateRangePicker */
    width: inherit !important;
    color: $white;
  }
}

.popover-help-text {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  border: unset;
  background-color: $white;
  color: $gray-600;
  border: 1px solid $gray-600;
  margin-left: 1rem;
  font-size: .7rem;
  margin-bottom: 1rem;
  &:hover {
    background-color: $gray-600;
    color: $white;
    border: 1px solid $white;
  }
}