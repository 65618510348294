
.badge-list {
  display: flex;
  flex-wrap: wrap;
}

.badge-rounded {
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 500px;

  i.ala-close, i.ala-trash {
    margin-left: 0.5rem;
    font-size: 12px;
    font-weight: 900;
    cursor: pointer;
  }
}

.badge-testimony {
  color: $white;
  background-color: $testimony-blue;
}

.badge-testimony-inverse {
  color: $testimony-blue;
  background-color: $white;
  border: 2px solid;
}

.badge-light-red {
  color: $white;
  background-color: $light-red;
}

.badge-light-red-inverse {
  color: $light-red;
  background-color: $white;
  border: 2px solid;
}