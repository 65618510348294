// animation speed and delay
//////////////////
.animation-fast
{
    -webkit-animation-duration: .1s !important;
       -moz-animation-duration: .1s !important;
         -o-animation-duration: .1s !important;
            animation-duration: .1s !important;
}

.animation-medium-speed
{
    -webkit-animation-duration: .750s !important;
       -moz-animation-duration: .750s !important;
         -o-animation-duration: .750s !important;
            animation-duration: .750s !important;
}

.animation-slow
{
    -webkit-animation-duration: 2s !important;
       -moz-animation-duration: 2s !important;
         -o-animation-duration: 2s !important;
            animation-duration: 2s !important;
}

.delay-1
{
    -webkit-animation-delay: .3s !important;
       -moz-animation-delay: .3s !important;
         -o-animation-delay: .3s !important;
            animation-delay: .3s !important;
}

.delay-2
{
    -webkit-animation-delay: .4s !important;
       -moz-animation-delay: .4s !important;
         -o-animation-delay: .4s !important;
            animation-delay: .4s !important;
}

.delay-3
{
    -webkit-animation-delay: .5s !important;
       -moz-animation-delay: .5s !important;
         -o-animation-delay: .5s !important;
            animation-delay: .5s !important;
}

.delay-4
{
    -webkit-animation-delay: .6s !important;
       -moz-animation-delay: .6s !important;
         -o-animation-delay: .6s !important;
            animation-delay: .6s !important;
}

.delay-5
{
    -webkit-animation-delay: .7s !important;
       -moz-animation-delay: .7s !important;
         -o-animation-delay: .7s !important;
            animation-delay: .7s !important;
}

.reverse {
  -webk-o-animation-direction: reverse !important;
     -m-o-animation-direction: reverse !important;
       -o-animation-direction: reverse !important;
          animation-direction: reverse !important;
}

// KeyFrameMixin
////////////////
@mixin custom-keyframe ($animation_name) {
  @-webkit-keyframes #{$animation_name} {
      @content;
  }

  @-moz-keyframes #{$animation_name} {
      @content;
  }

  @-o-keyframes #{$animation_name} {
      @content;
  }

  @keyframes #{$animation_name} {
      @content;
  }
}

@mixin custom-animation ($delay, $duration, $animation, $mode) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: $mode;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: $mode;

  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-name: $animation;
  -o-animation-fill-mode: $mode;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: $mode;
}

// remaining point pulse animation https://codepen.io/nzbin/pen/GGrXbp
.dot-stage {
  display: inline-flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 0;
  margin: 0 -5%;
  overflow: hidden;
}
.dot-pulse {
  margin-left: 2rem;
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $black;
  color: $black;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $black;
  color: $black;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}
@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}


@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}


// Fade animations
//////////////////

@mixin customFade($anim_name, $x_from_translation, $y_from_translation, $opacity_from, $x_to_translation, $y_to_translation, $opacity_to) {

    @include custom-keyframe($anim_name) {
      0%
      {
          -webkit-transform: translate(#{$x_from_translation}, #{$y_from_translation});
             -moz-transform: translate(#{$x_from_translation}, #{$y_from_translation});
               -o-transform: translate(#{$x_from_translation}, #{$y_from_translation});
                  transform: translate(#{$x_from_translation}, #{$y_from_translation});

          opacity: $opacity_from;
      }
      100%
      {
          -webkit-transform: translate(#{$x_to_translation}, #{$y_to_translation});
             -moz-transform: translate(#{$x_to_translation}, #{$y_to_translation});
               -o-transform: translate(#{$x_to_translation}, #{$y_to_translation});
                  transform: translate(#{$x_to_translation}, #{$y_to_translation});

          opacity: $opacity_to;
      }
    }
}


@include customFade(fadeInDown, 0, 30px, 0, 0, 0, 1);
@include customFade(fadeOutDown, 0, 0, 1, 0, 30px, 0);
@include customFade(fadeInUp, 0, -30px, 0, 0, 0, 1);
@include customFade(fadeOutUp, 0, 0, 1, 0, -30px, 0);
@include customFade(fadeInLeft, -30px, 0, 0, 0, 0, 1);
@include customFade(fadeOutLeft, 0, 0, 1, -30px, 0, 0);
@include customFade(fadeInRight, 30px, 0, 0, 0, 0, 1);
@include customFade(fadeOutRight, 0, 0, 1, 30px, 0, 0);

.animate.fadeInDown
{
   @include custom-animation(0s, 0.75s, fadeInDown, both)
}

.animate.fadeOutDown
{
    @include custom-animation(0s, 0.75s, fadeOutDown, both)
}

.animate.fadeInUp
{
   @include custom-animation(0s, 0.75s, fadeInUp, both)
}

.animate.fadeOutUp
{
    @include custom-animation(0s, 0.75s, fadeOutUp, both)
}

.animate.fadeInLeft
{
   @include custom-animation(0s, 0.75s, fadeInLeft, both)
}

.animate.fadeOutLeft
{
    @include custom-animation(0s, 0.75s, fadeOutLeft, both)
}

.animate.fadeInRight
{
   @include custom-animation(0s, 0.75s, fadeInRight, both)
}

.animate.fadeOutRight
{
    @include custom-animation(0s, 0.75s, fadeOutRight, both)
}
