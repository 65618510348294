// Text weight
//////////////
.text-weight-medium {
  font-weight: 500;
}

// Test word-break
//////////////////
.text-word-break-all {
  word-break: break-all;
}

.text-word-break-word {
  word-break: break-word;
}