[v-cloak] { display: none; }

// vue modal
////////////
/* css class for the transition */
.fade-slow-enter-active,
.fade-slow-leave-active {
    transition: opacity 1s;
}
.fade-slow-enter,
.fade-slow-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-quick-enter-active,
.fade-quick-leave-active {
    transition: opacity 0.3s;
}
.fade-quick-enter,
.fade-quick-leave-to {
    opacity: 0;
}

.popup-modal {
    background-color: rgba(255, 255, 255, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    z-index: 10000;
}

.window {
    background: #f6f6f6;
    border-radius: 20px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 800px;
    width: 100%;
    max-height: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow-y: auto;

  &_background {
    padding: 3rem;
  }
}

%visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

// vue confirm modal
////////////////////
.modal-icon {
  i {
    font-size: 48px;
    margin-right: 30px;
  }
}

.modal-title {
  font-size: 20px;
  text-align: center;
}

.modal-message {
  font-size: 16px;
  text-align: center;
}

.modal-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      @extend .btn;
      border-radius: 500px;
      font-size: 20px;
      min-width: 90px;
      margin: 25px 20px;
    }
    .ok-btn {
      background-color: $electric-blue;
      color: $white;
    }

    .cancel-btn {
      background-color: #dedede;
      color: $black;
    }
}

.bubble-rating {

  &__bubble {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    width: 12px;
    height: 12px;
    margin: 2px;
    box-shadow: $default-shadow;
    border-radius: 50%;
    transition: color .2s ease-out;
    background-color: $white;

    &:hover {
      cursor: pointer;
    }

    &.is-selected {
      background-color: $electric-blue;
    }

    &.is-disabled:hover {
      cursor: default;
    }
  }

  &__checkbox {
    @extend %visually-hidden;
  }
}